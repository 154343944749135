<template>
	<div class="tqrcode">
		<div class="searchw">
			<Form :label-width="70" inline>
				<FormItem label="入口域名">
					<Input v-model="posdata.domain" @on-keyup.13="init"></Input>
				</FormItem>
        <FormItem label="落地域名" v-if="false">
          <Input v-model="posdata.url" @on-keyup.13="init"></Input>
        </FormItem>
				<Button type="primary" icon="md-search" @click="init">搜索</Button>
        <Button style="float: right;" type="info" icon="md-add" @click="add_qrcode">添加推广码</Button>
			</Form>

		</div>
		<div class="ivu-table-wrapper">
      <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
        <template slot-scope="row" slot="status">
          <i-switch size="large" v-model="data.data[row.index].status" :true-value="1" :false-value="0" :loading="row.row.status_loading" @on-change="changeStatus($event,row.index)">
            <span slot="open">正常</span>
            <span slot="close">禁用</span>
          </i-switch>
        </template>
        <template slot-scope="row" slot="alldata">
          <div :class="'url_list status'+p.status" v-for="(p,key) in row.row.alldata" :key="key">
            <span>{{p.url||'--'}}</span>
            <span class="url_status">--{{p.status?'正常':'停用'}}</span>
          </div>
        </template>
        <template slot-scope="row" slot="action">
          <Button type="info" size="small" @click="edit_code(row.index)">编辑</Button>
          <Button type="primary" size="small" style="margin-left: 10px;" @click="show_qrcode(row.row.id)">查看二维码</Button>
          <Button type="success" size="small" style="margin-left: 10px;" @click="download_qrcode(row.row.id)">下载二维码</Button>
        </template>
      </Table>
		</div>
		<div class="footer-m">
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50,100]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
		</div>
    <edit-qrcode ref="editqrcode" @saveOk="init"></edit-qrcode>
    <Modal v-model="qrcode.status" title="查看二维码" width="234" footer-hide>
      <img :src="qrcode.data" />
    </Modal>
	</div>
</template>

<script>
  import editQrcode from "@/views/attach/edit_qrcode"
	export default {
		name: "Qrcode",
    components:{editQrcode},
		data(){
			return{
				columns:[
					{title:'ID',key:'id',width: 100},
					{title:'入口域名',key:'domain',minWidth:150},
					{title:'落地域名',slot:'alldata',minWidth:320},
					{title:'状态',slot:'status',minWidth: 60},
					{title:'设置时间',key:'re_time',minWidth: 130},
          {title: '操作',slot:'action',width:252,align:'center'},
				],
        table_loading:false,
				data:{},
        qrcode:{
          status:false,
          data:'',
        },
        posdata:{
          domain:'',
          url:'',
          page:1,
          page_size:15
        }
			}
		},
		created() {
			this.init();
		},
		methods:{
			init(){
        var _this=this;
        this.table_loading=true;
        this.requestApi('/adm/qrcode.html',this.posdata).then(function (res){
          _this.table_loading=false;
          if(res.status==1){
            _this.data=res.data;
          }
        })
			},
      /**
       * 修改二维码
       */
      edit_code:function (key){
        this.$refs.editqrcode.open(this.data.data[key]);
      },
      /**
       * 添加推广码
       */
      add_qrcode:function (){
        this.$refs.editqrcode.open({id:0,domain:'',alldata:[{url:'',status:1}]});
      },
      /**
       * 切换状态
       */
      changeStatus:function (status,key){
        let _this=this,msg=status?'确认启用吗？':'确认禁用吗？';
        this.$set(this.data.data[key],'status_loading',true);
        this.confirm(msg,function (){
          _this.requestApi('/adm/change_qrcode_status.html',{'id':_this.data.data[key].id,'status':status}).then(function (res){
            _this.data.data[key].status_loading=false;
            if(res.status!=1){
              _this.data.data[key].status=status?0:1;
              _this.alertError(res.msg);
            }
          })
        },function (){
          _this.data.data[key].status=status?0:1;
          _this.data.data[key].status_loading=false;
        })
      },
      /**
       * 查看二维码
       */
      show_qrcode:function (id){
        var _this=this;
        this.table_loading=true;
        this.requestApi('/adm/show_qrcode.html',{'id':id}).then(function (res){
          _this.table_loading=false;
          if(res.status==1){
            _this.qrcode={status:true,data:res.data};
          }
        })

      },
      /**
       * 下载二维码
       */
      download_qrcode(id){
          var iframe,file=this.ApiUrl+'/adm/download_qrcode.html?id='+id;
          try{
            iframe = document.createElement('<iframe style="width:0;height:0" src='+file+'></iframe>');
          }catch(e){
            iframe = document.createElement('iframe');
            iframe.style = 'width:0;height:0';
            iframe.src=file;
          }
          document.body.appendChild(iframe);
      },
      /**
       * 翻页
       */
      page:function (page){
        this.posdata.page=page;
        this.init();
      },
      /**
       * 切换分页条数
       */
      change_pagesize:function (size){
        this.posdata.page_size=size;
        if(this.posdata.page==1) this.init();
      },
		}
	}
</script>

<style lang="less">
@import "~@/assets/css/qrcode/qrcode";
</style>
