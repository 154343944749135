<template>
    <Modal class-name="editqrcode" v-model="editStatus" title="修改推广码" width="600" @on-ok="commitData" @on-visible-change="close" :loading="subLoading" :closable="false">
      <Form label-position="right" :label-width="70">
        <FormItem label="入口域名">
          <Input type="text" v-model="editData.domain" maxlength="30" placeholder="请填写入口域名"></Input>
        </FormItem>
        <template v-for="(p,key) in editData.alldata">
          <FormItem label="落地域名" :key="key" v-if="p.status<2">
            <Input class="ldyminp" type="text" v-model="p.url" maxlength="30" placeholder="可填写完整url地址"></Input>
            <Button class="scldym" type="error" size="small" @click="del_url(key)">删除</Button>
            <i-switch class="ldymzt" size="large" v-model="editData.alldata[key].status" :true-value="1" :false-value="0">
              <span slot="open">正常</span>
              <span slot="close">禁用</span>
            </i-switch>
          </FormItem>
        </template>
        <FormItem label="">
          <Button type="primary" size="small" @click="add_url">添加落地域名</Button>
        </FormItem>
      </Form>
    </Modal>
</template>

<script>
export default {
  name:'edit_qrcode',
  data(){
    return {
      editStatus:false,
      subLoading:true,
      editData:{},
      serLoading:false
    }
  },
  methods:{
    open:function(data){
      this.editStatus = true
      this.editData=JSON.parse(JSON.stringify(data));
    },
    /**
     * 提交保存
     */
    commitData:function(){
      let _this = this;
      this.requestApi('/adm/save_qrcode.html', this.editData).then(function (res){
        if(res.status == 1){
          _this.tipSuccess(res.msg);
          _this.$emit('saveOk');
          _this.editStatus = false;
        }else{
          _this.subLoading = false;
          setTimeout(function (){_this.subLoading = true;},5);
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 删除落地域名
     */
    del_url:function (key){
      var _this=this;
      if(_this.editData.alldata[key].url==''){
        _this.editData.alldata.splice(key,1);
        return false;
      }
      this.confirm('确定要删除吗？',function (){
        _this.editData.alldata[key].status=2;
      })
    },
    /**
     * 添加落地域名
     */
    add_url:function (){
      var length=0;
      for(var i in this.editData.alldata){
        if(this.editData.alldata[i].status<2) length++;
      }
      if(length>=10) return this.alertError('最多添加10个落地域名！');
      this.editData.alldata.push({url:'',status:1});
    },
    /**
     * 关闭 modal
     */
    close:function(status){
      if(!status){
        this.editData={};
      }
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/qrcode/qrcode";
</style>